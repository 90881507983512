<template>
  <div class="property-wrapper">
    <template v-if="control">
      <ControlBaseProperties v-model="control" />
      <ControlSpecificPanel :control="control">
        <div class="row">
          <ImageSelection
            class="col-xs-12"
            v-model="control.synopticComponent.src"
            @size="setOriginalSize($event)"
          />
        </div>
      </ControlSpecificPanel>
    </template>
  </div>
</template>

<script>
import { isEqual } from "lodash";
import ControlBaseProperties from "./control-base-properties";
import BaseControl from "./base-control.vue";
import ImageSelection from "./image-selection";
import ControlSpecificPanel from "@/components/editor/control-specific-panel.vue";

import Controls from "@/assets/dashboard/controls.json";

const dftControl = () => {
  return (
    Controls.find(
      (i) => i.template.synopticComponent.componentName == "SynopticImage"
    )?.template ?? null
  );
};

export default {
  name: "SynopticImage",
  extends: BaseControl,
  components: {
    ControlBaseProperties,
    ImageSelection,
    ControlSpecificPanel
  },
  data() {
    return {
      control: null
    };
  },
  watch: {
    control: {
      deep: true,
      handler(val) {
        this.updateControl({
          id: val.id,
          control: {
            ...val,
            synopticComponent: {
              ...val.synopticComponent,
              value: val.synopticComponent.value
            }
          },
          noMerge: true
        });
      }
    },
    controls: {
      deep: true,
      handler(val) {
        let n = JSON.parse(JSON.stringify(val[0]));
        let o = JSON.parse(JSON.stringify(this.control));
        if (!isEqual(n, o)) {
          this.control = JSON.parse(JSON.stringify(n));
        }
      }
    }
  },
  methods: {
    setOriginalSize(size) {
      let ori = dftControl().synopticComponent;
      let cur = this.control.synopticComponent;
      let nSrc = ((size && size?.src) || "").split("?")[0];
      let oSrc = (ori?.src || "").split("?")[0];
      if (
        size &&
        size.width &&
        size.height &&
        ((oSrc && nSrc && nSrc != oSrc) ||
          (ori.clientRect.width == cur.clientRect.width &&
            ori.clientRect.height == cur.clientRect.height))
      ) {
        this.control.synopticComponent.clientRect.width = size.width;
        this.control.synopticComponent.clientRect.height = size.height;
      }
    },
    updateControl(payload) {
      this.$store.dispatch("synoptic/updateControl", payload);
    }
  },
  mounted() {
    this.control = JSON.parse(JSON.stringify(this.controls[0]));
  }
};
</script>
